<template>
  <b-card>
       <b-alert
        v-height-fade
        dismissible
        v-model="showDismissibleAlert"
        fade
        class="mb-2"
        variant="danger"
        v-id="showDismissibleAlert == true"
      >
        <div class="alert-body">
          <span>{{ messages }}</span>
        </div>
      </b-alert>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <!-- <b-img
            ref="image_tmb"
            rounded
            :src="model.avatar"
            height="80"
          /> -->
          <b-avatar ref="image_tmb" :src="model.profile_picture" class="mb-1" size="90px"></b-avatar>
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.file.$el.click()"
        >
          Ubah
        </b-button>
        <b-form-file
          ref="file"
          @input="clickref"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
        />
        <!--/ upload button -->
        <b-card-text>Allowed JPG or PNG. Max size of 2Mb</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(save)" class="mt-2">
        <b-row>
          <b-col md="6">
            <ValidationProvider
              name="Nama Lengkap"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Nama Lengkap" label-for="mc-name">
                <b-form-input
                  id="mc-name"
                  placeholder="Masukkan Nama Lengkap"
                  v-model="model.full_name"
                />

                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider
              name="Alamat Email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <b-form-group label="Alamat Email" label-for="mc-name">
                <b-form-input
                  id="mc-email"
                  placeholder="Masukkan Alamat Email"
                  v-model="model.email"
                />

                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
            >
              Simpan Perubahan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormInvalidFeedback,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      messages: "",
      showDismissibleAlert:false,
      genderOptions: [
        { text: "Male", value: 1 },
        { text: "Female", value: 2 },
      ],
      errors: {},
      config: {
        api: "/profile",
      },
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      model: {
        img_thumbnail: null,
      },
    };
  },
  mounted() {
    let _ = this;
    _.getData();
  },
  methods: {
    clickref()
    {
      this.model.avatar =  this.$refs.file.files[0]
      let reader = new FileReader();
      reader.onload = (e) => {
          this.$refs.image_tmb.src = reader.result;
      };
      reader.readAsDataURL(this.model.avatar);
      this.$forceUpdate()
      // this.$refs.file.$el.click()
      // this.model.banner = URL.createObjectURL(img);
    },
    getData() {
      axios
        .get(this.config.api)
        .then((resp) => {
          let _ = this;
          _.model = resp.data.data;
          _.model.full_name = _.model.full_name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push("/login");
    },
    save() {
      let _ = this;
      let formData = new FormData();

      if (this.$refs.file.files[0] != undefined) {
        formData.append("full_name", _.model.full_name);
        formData.append("email", _.model.email);
        formData.append("profile_picture",this.$refs.file.files[0]);
      } else {
          formData.append("full_name", _.model.full_name);
          formData.append("email", _.model.email);
      }
      
      axios
        .put(_.config.api , formData)
        .then((response) => {
          _.$toast({
            component: ToastificationContent,
            props: {
              title: "Profil berhasil diubah. Anda akan diarahkan ke halaman login...",
              icon: "EditIcon",
              variant: "success",
            },
          });

          setTimeout(() => {
            this.logout();
          }, 5000);
        })
        .catch((e) => {
          let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
          } else {
            vm.messages = e.response.data.message;
          }
          console.log(vm.messages);
        });
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
