<template>
  <b-card>
    <!-- form -->
    <b-alert
        v-height-fade
        dismissible
        v-model="showDismissibleAlert"
        fade
        class="mb-2"
        variant="danger"
        v-id="showDismissibleAlert == true"
      >
        <div class="alert-body">
          <span>{{ messages }}</span>
        </div>
      </b-alert>
    <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(save)">
      <b-row>
        <!-- old password -->
        <b-col md="12">
           <ValidationProvider
            name="Password Lama"
            rules='required|min:8'
            v-slot="{ errors }"
          >
          <b-form-group
            label-for="account-old-password"
            label="Password Lama"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="model.old_password"
                :type="passwordFieldTypeOld"
                
                name="old-password"
                placeholder="Masukkan Password Lama"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
              <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
          </b-form-group>
           </ValidationProvider>
        </b-col>
        <!-- old password -->

        <!-- new password -->
        <b-col md="12">
           <ValidationProvider
            name="Password Baru"
            rules='required|min:8'
            vid="confirmation"
            v-slot="{ errors }"
          >
          <b-form-group
            label-for="account-new-password"
            label="Password Baru"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="model.new_password"
                :type="passwordFieldTypeNew"
                
                name="new-password"
                placeholder="Masukkan Password Baru"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
              <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
          </b-form-group>
           </ValidationProvider>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="12">
          <ValidationProvider
                  name="Konfirmasi Password"
                  rules='required|min:8|confirmed:confirmation'
                  v-slot="{ errors }"
                >
          <b-form-group
            label-for="account-retype-new-password"
            label="Konfirmasi Password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="model.password_confirmation"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                placeholder="Masukkan Konfirmasi Password Baru"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
              <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
          </b-form-group>
           </ValidationProvider>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            type="submit"
          >
            Simpan Perubahan
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Reset
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config:{
        api:'/profile/change_password'
      },
      messages: "",
      errors: {},
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      model:{},
      showDismissibleAlert:false,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    let _ = this;
  },
  methods: {
     logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push("/login");
    },
    save()
    {
        let _ = this
        let datas = {
          new_password : this.model.new_password,
          password_confirmation : this.model.password_confirmation,
          old_password : this.model.old_password
        }

        axios.put(_.config.api, datas)
        .then(res => {
           _.$toast({
            component: ToastificationContent,
            props: {
              title: "Update Password Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
           setTimeout(() => {
            this.logout();
          }, 5000);
        }).catch(e => {
           let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
          } else {
            vm.messages = e.response.data.message;
          }
          console.log(vm.messages);              
        })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
